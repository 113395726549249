import { TranslationModel } from '@/common/models';
import httpClient from './http-client';

const resource = '/api/translations';

export function getTranslationsPage(pageNumber: number, pageLength: number) {
  return httpClient.get(resource, { params: { pageNumber, pageLength } });
}

export function getCountOfTranslationsPages(pageLength: number) {
  return httpClient.get(`${resource}/pages-count`, { params: { pageLength } });
}

export function getTranslation(id: string) {
  return httpClient.get(`${resource}/${id}`);
}

export function addTranslation(translation: TranslationModel) {
  return httpClient.put(resource, translation);
}

export function editTranslation(id: string, translation: TranslationModel) {
  return httpClient.post(`${resource}/${id}`, translation);
}

export function removeTranslation(id: string) {
  return httpClient.delete(`${resource}/${id}`);
}
