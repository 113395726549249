

































import { Component, Vue, Watch } from 'vue-property-decorator';
import { SearchType } from '@/common/enums';

@Component
export default class Search extends Vue {
  searchString = '';
  searchType = SearchType.etymological;

  get query(): string {
    return this.$store.state.search.query;
  }

  get type(): SearchType {
    return this.$store.state.search.type;
  }

  get keyboardOpened(): boolean {
    if (this.$store.state.keyboard.input) {
      return this.$store.state.keyboard.input.id === 'searchInput';
    } else {
      return false;
    }
  }

  @Watch('query')
  queryWatch() {
    this.searchString = this.query;
  }

  @Watch('type')
  typeWatch() {
    this.searchType = this.type;
  }

  @Watch('searchString')
  searchStringWatch() {
    this.$store.commit('updateSearchQuery', this.searchString);
  }

  @Watch('searchType')
  searchTypeWatch() {
    this.$store.commit('updateSearchType', this.searchType);
  }

  search() {
    if (this.searchString && this.searchString.trim()) {
      const type = typeof this.$route.query.type === 'string' ? SearchType[this.$route.query.type.toLowerCase()] : null;
      const query = typeof this.$route.query.query === 'string' ? this.$route.query.query.toLowerCase() : null;
      if (this.$route.name !== 'search' || (type && type !== this.searchType) || (query && query !== this.searchString)) {
        this.$router.push({ name: 'search', query: { type: SearchType[this.searchType], query: this.searchString } });
      }
    } else if (this.$route.name !== 'home') {
      this.$router.push({ name: 'home', query: { page: this.$store.state.translations.currentPage.toString() } });
    }
  }

  clear() {
    this.searchString = '';
    this.search();
  }

  onInputClick(event: MouseEvent) {
    event.cancelBubble = true;
  }

  switchKeyboard(event: MouseEvent) {
    this.$store.commit('switchKeyboard', document.querySelector('#searchInput'));
    event.cancelBubble = true;
  }
}
