































import { Component, Vue } from 'vue-property-decorator';
import Search from '@/components/Search.vue';
import Translation from '@/components/Translation.vue';
import VueHeadful from 'vue-headful';

@Component({
  components: {
    Search,
    Translation,
    VueHeadful,
  },
})
export default class Home extends Vue {
  fab = false;
  exportLink = `${process.env.VUE_APP_API_URL}/api/export`;

  get title() {
    const titleParts: string[] = [];

    switch (this.$route.name) {
      case 'home':
        if (typeof this.$route.query.page === 'string' && this.$route.query.wordlist === 'true') {
          titleParts.push(`List page ${this.$route.query.page}`);
        } else if (typeof this.$route.query.page === 'string') {
          titleParts.push(`Page ${this.$route.query.page}`);
        }
        break;
      case 'search':
        if (typeof this.$route.query.query === 'string') {
          titleParts.push(this.$route.query.query);
        }
        break;
      case 'edit':
        titleParts.push('Edit');
        break;
      case 'add':
        titleParts.push('Add');
        break;
    }

    titleParts.push('Concordance');

    return titleParts.join(' | ');
  }

  onClick() {
    if (this.$route.hash) {
      this.$router.replace({ name: this.$route.name, query: this.$route.query });
    }

    this.$store.commit('closeKeyboard');
  }
}
