







import { Component, Vue } from 'vue-property-decorator';
import Keyboard from '@/components/Keyboard.vue';

@Component({
  components: {
    Keyboard,
  },
})
export default class App extends Vue {
  get keyboardIsVisible() {
    return this.$store.state.keyboard.isVisible;
  }

  get keyboardInput() {
    return this.$store.state.keyboard.input;
  }
}
