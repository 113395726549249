









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslationModel } from '@models';
import { Letters } from '@/common/constants';
import VueScrollTo from 'vue-scrollto';

@Component
export default class Translation extends Vue {
  @Prop() translation: TranslationModel;
  @Prop({ default: false }) isCollapsible: boolean;
  @Prop({ default: false }) collapsed: boolean;
  @Prop({ default: true }) editable: boolean;
  @Prop({ default: false }) focused: boolean;

  letters = Letters;
  hideLetters = false;

  @Watch('collapsed')
  collapsedWatch() {
    this.hideLetters = this.collapsed;
  }

  @Watch('focused')
  focusedWatch() {
    this.focusAnimate();
  }

  mounted() {
    this.hideLetters = this.collapsed;
    this.focusAnimate();
  }

  onClick(event: MouseEvent) {
    this.$emit('click', { id: this.translation._id, event });
  }

  onTitleClick() {
    if (this.isCollapsible) {
      this.hideLetters = !this.hideLetters;
    }
  }

  onEditClick(event: MouseEvent) {
    event.cancelBubble = true;
  }

  getLetterValue(key: string) {
    return this.letters.find(letter => letter.key === key).value;
  }

  private focusAnimate() {
    if (this.focused) {
      setTimeout(() => {
        VueScrollTo.scrollTo(`#id_${this.translation._id}`, {
          easing: 'ease-in',
          offset: -200,
          force: false,
          cancelable: true,
          x: false,
          y: true,
        });
      });
    }
  }
}
