export const Letters = [
  { key: 'b0l0', value: '15 February 1922', position: 1 },
  { key: 'b0l1', value: '19 December 1923', position: 3 },
  { key: 'b0l2', value: '1924', position: 4 },
  { key: 'b0l3', value: '8 June 1925', position: 5 },
  { key: 'b0l4', value: 'August 1927', position: 7 },
  { key: 'b0l5', value: 'January 1929', position: 9 },
  { key: 'b1l0', value: 'Naw-Rúz 108 [1951]', position: 19 },
  { key: 'b1l1', value: 'Naw-Rúz 111 [1954]', position: 20 },
  { key: 'b1l2', value: '13 December 1932', position: 13 },
  { key: 'b1l3', value: 'December 1937', position: 14 },
  { key: 'b1l4', value: 'Naw-Rúz 101 [1944]', position: 16 },
  { key: 'b1l5', value: 'Ridván 105 [1948]', position: 17 },
  { key: 'b1l6', value: 'Naw-Rúz 113 [1956]', position: 21 },
  { key: 'b2l0', value: '27 February 1923', position: 2 },
  { key: 'b2l1', value: 'February 1927', position: 6 },
  { key: 'b2l2', value: 'November 1927', position: 8 },
  { key: 'b2l3', value: '27 November 1929', position: 10 },
  { key: 'b2l4', value: 'Naw-Rúz 88 [1931]', position: 11 },
  { key: 'b2l5', value: 'Ridván 89 [1932]', position: 12 },
  { key: 'b2l6', value: '25 December 1939', position: 15 },
  { key: 'b2l7', value: 'Naw-Rúz 110 [1953]', position: 19 },
];
