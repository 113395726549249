import { SearchType } from '@/common/enums';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/Translations.vue'),
        props: router => ({
          pageNumber: typeof router.query.page === 'string' ? parseInt(router.query.page) : null,
          wordlist: router.query.wordlist === 'true',
          wordHash: router.hash.replace('#', ''),
          force: router.query.force === 'true',
        }),
        children: [
          {
            path: 'edit/:id',
            name: 'edit',
            component: () => import('../views/Edit.vue'),
            props: true,
          },
          {
            path: 'add',
            name: 'add',
            component: () => import('../views/Add.vue'),
          },
        ],
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('../views/Search.vue'),
        props: router => ({
          type: typeof router.query.type === 'string' ? SearchType[router.query.type.toLowerCase()] : null,
          query: typeof router.query.query === 'string' ? router.query.query.toLowerCase() : null,
        }),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from.name === 'search' && to.name === 'home') {
      return { x: 0, y: 0 };
    } else {
      return savedPosition;
    }
  },
});

export default router;
