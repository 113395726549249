






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueTouchKeyboard from 'vue-touch-keyboard';
import 'vue-touch-keyboard/dist/vue-touch-keyboard.css';

@Component({
  components: {
    'vue-touch-keyboard': VueTouchKeyboard.component,
  },
})
export default class Keyboard extends Vue {
  @Prop() input: HTMLInputElement;

  lang = 'arab';
  layouts = {
    arab: {
      _meta: {
        shiftl: { keySet: 'shift', text: 'Shift', width: 100, classes: 'control' },
        shiftr: { keySet: 'shift', text: 'Shift', width: 100, classes: 'control' },
        space: { key: ' ', text: 'Space', width: 90 },
        backspace: { func: 'backspace', classes: 'control backspace', width: 65 },
        next: { func: 'next', text: '🌐', width: 10, classes: 'control featured' },
      },
      default: [
        'ذ ١ ٢ ٣ ٤ ٥ ٦ ٧ ٨ ٩ ٠ - = {backspace}',
        'ض ص ث ق ف غ ع ه خ ح ج د \\',
        'ش س ي ب ل ا ت ن م ك ط',
        '{shiftl} ئ ء ؤ ر ﻻ ى ة و ز ظ {shiftr}',
        '{next} {space}',
      ],
      shift: [
        '\u0651 ! @ # $ % ^ & * ) ( _ + {backspace}',
        '\u064E \u064B \u064F \u064C \ufef9 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |',
        '\u0650 \u064D ] [ \ufef7 \u0623 \u0640 \u060C / : "',
        '{shiftl} ~ \u0652 } { \ufef5 \u0622 \u2019 , . \u061F {shiftr}',
        '{next} {space}',
      ],
    },
    persian: {
      _meta: {
        shiftl: { keySet: 'shift', text: 'Shift', width: 100, classes: 'control' },
        shiftr: { keySet: 'shift', text: 'Shift', width: 100, classes: 'control' },
        space: { key: ' ', text: 'Space', width: 90 },
        backspace: { func: 'backspace', classes: 'control backspace', width: 65 },
        next: { func: 'next', text: '🌐', width: 10, classes: 'control featured' },
      },
      default: [
        '~ \u06f1 \u06f2 \u06f3 \u06f4 \u06f5 \u06f6 \u06f7 \u06f8 \u06f9 \u06f0 - = {backspace}',
        '\u0636 \u0635 \u062b \u0642 \u0641 \u063a \u0639 \u0647 \u062e \u062d \u062c \u0686 \\',
        '\u0634 \u0633 \u06cc \u0628 \u0644 \u0627 \u062a \u0646 \u0645 \u06a9 \u06af',
        '{shiftl} \u0638 \u0637 \u0632 \u0631 \u0630 \u062f \u067e \u0648 . / {shiftr}',
        '{next} {space}',
      ],
      shift: [
        '\u00d7 ! @ # $ \ufdfc % ^ & * ) ( _ + {backspace}',
        '\u064b \u064c \u064d \u0631 \u064a \u0627 \u0644 \u060c \u061b , ] [ \\ } {',
        '\u064e \u064f \u0650 \u0651 \u06c0 \u0622 \u0640 \u00ab \u00bb : "',
        '{shiftl} | \u0629 \u0626 \u0698 \u0624 \u0625 \u0623 \u0621 < > / {shiftr}',
        '{next} {space}',
      ],
    },
  };

  @Watch('input')
  inputWatch() {
    this.input.focus();
  }

  changeLanguage() {
    if (this.lang === 'arab') {
      this.lang = 'persian';
    } else if (this.lang === 'persian') {
      this.lang = 'arab';
    }
  }
}
