import { SearchType } from '@/common/enums/search-type.enum';
import router from '@/router';
import { SearchService, TranslationService } from '@/services';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    search: { query: null, type: SearchType.etymological, results: null },
    translations: {
      currentPage: 0,
      pageLength: 15,
      pageTranslations: null,
      countOfPages: 0,
      wordList: false,
    },
    keyboard: {
      isVisible: false,
      input: null,
    },
    edit: {
      translation: null,
      result: null,
    },
    add: {
      result: null,
    },
  },
  mutations: {
    updateSearchQuery(state, payload) {
      state.search.query = payload;
    },
    updateSearchType(state, payload) {
      state.search.type = payload;
    },
    updateSearchState(state, payload) {
      state.search = payload;
    },
    updateTranslationsState(state, payload) {
      state.translations.pageTranslations = payload.translations;
      state.translations.currentPage = payload.pageNumber;
    },
    updateWordList(state, payload) {
      state.translations.wordList = payload;
    },
    updateCountOfPages(state, payload) {
      state.translations.countOfPages = payload;
    },
    switchKeyboard(state, payload) {
      if (state.keyboard.input === payload) {
        state.keyboard.isVisible = false;
        state.keyboard.input = null;
      } else {
        state.keyboard.isVisible = true;
        state.keyboard.input = payload;
      }
    },
    newKeyboardFieldFocused(state, payload) {
      if (state.keyboard.isVisible && state.keyboard.input !== payload) {
        state.keyboard.input = payload;
      }
    },
    closeKeyboard(state) {
      state.keyboard.isVisible = false;
      state.keyboard.input = null;
    },
    updateEditableTranslation(state, payload) {
      state.edit.translation = payload;
    },
    updateEditResult(state, payload) {
      state.edit.result = payload;
    },
    updateAddResult(state, payload) {
      state.add.result = payload;
    },
  },
  actions: {
    async getCountOfTranslationsPages(store) {
      const response: AxiosResponse = await TranslationService.getCountOfTranslationsPages(store.state.translations.pageLength);
      store.commit('updateCountOfPages', response.data);
    },
    async getTranslationsPage(store, payload) {
      try {
        if (payload.pageNumber !== store.state.translations.currentPage || payload.force) {
          const page = Math.min(Math.max(0, payload.pageNumber - 1), store.state.translations.countOfPages - 1);
          if (page === payload.pageNumber - 1) {
            const response: AxiosResponse = await TranslationService.getTranslationsPage(page, store.state.translations.pageLength);
            store.commit('updateTranslationsState', { translations: response.data, pageNumber: payload.pageNumber });
          } else {
            store.commit('updateTranslationsState', { translations: [], pageNumber: 0 });
          }
        }
      } catch (e) {
        store.commit('updateTranslationsState', { translations: [], pageNumber: 0 });
      }
    },
    async search(store, payload) {
      try {
        const response: AxiosResponse = await SearchService.search(payload.query, payload.type);
        store.commit('updateSearchState', { query: payload.query, type: payload.type, results: response.data });
      } catch (e) {
        store.commit('updateSearchState', { query: payload.query, type: payload.type, results: [] });
      }
    },
    async loadTranslationToEdit(store, payload) {
      try {
        let translation = null;
        if (store.state.translations.pageTranslations) {
          const translations = store.state.translations.pageTranslations.reduce((acc, group) => {
            acc.push(...group.translations.map(t => ({ ...t, root: group.root })));
            return acc;
          }, []);
          translation = translations.find(t => t._id === payload);
        }

        if (!translation) {
          const response: AxiosResponse = await TranslationService.getTranslation(payload);
          translation = response.data;
        }

        store.commit('updateEditableTranslation', translation);
      } catch (e) {
        console.error(e);
        router.push({ name: 'home', query: { page: store.state.translations.currentPage.toString() } });
      }
    },
    async addTranslation(store, payload) {
      try {
        await TranslationService.addTranslation({
          headword: payload.headword,
          translit: payload.translit,
          root: payload.root,
          position: payload.position,
          letters: payload.letters,
          note: payload.note,
          citationNotes: payload.citationNotes,
        });
        store.commit('updateAddResult', { success: true });
      } catch (e) {
        console.error(e);
        store.commit('updateAddResult', { success: false, errorMessage: 'Cannot add headword. Please try later.' });
      }
    },
    async editTranslation(store, payload) {
      try {
        await TranslationService.editTranslation(payload._id, {
          headword: payload.headword,
          translit: payload.translit,
          root: payload.root,
          position: payload.position,
          letters: payload.letters,
          note: payload.note,
          citationNotes: payload.citationNotes,
        });
        store.commit('updateEditResult', { success: true });
      } catch (e) {
        console.error(e);
        store.commit('updateEditResult', { success: false, errorMessage: 'Cannot save changes. Please try later.' });
      }
    },
    async removeTranslation(store, payload) {
      try {
        await TranslationService.removeTranslation(payload);
        store.commit('updateEditResult', { success: true });
      } catch (e) {
        console.error(e);
        store.commit('updateEditResult', { success: false, errorMessage: 'Cannot remove translation. Please try later.' });
      }
    },
  },
  modules: {},
});
